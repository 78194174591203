import { Alert } from "react-bootstrap";

function ToastModal(props) {


    return (
        <>
            <Alert className="login-alerts position-absolute top-0 end-0 p-3 mt-100 mr-20" variant="success">
            {props.message}
                        </Alert>
        </>
    )
}
export default ToastModal;


