import React, { useState, useEffect } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import loading from "../../../assets/images/loader_grey.gif";
import { createPresentation, getPresentationDetails, updatePresentation } from '../../../services/presentation';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getLayouts } from '../../../services/layouttype';
import AlertSuccess from '../../AlertSuccess';


function Configuration(props) {
    const params = useParams();
    const code = params.eventCode;

    const [eventName, setEventName] = useState("");
    const [title, setTitle] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [alertMsg, setAlertMsg] = useState(false);
    const [errName, setErrName] = useState(false);
    const [errTitle, setErrTitle] = useState(false);
    const [errVideoUrl, setErrVideoUrl] = useState(false);
    const [errLayoutType, setErrLayoutType] = useState(false);
    const [errVideoType, setErrVideoType] = useState(false);
    const [metaKey, setMetaKey] = useState("");
    const [metaData, setMetaData] = useState("");
    const [showQuestions, setShowQuestions] = useState("");
    const [status, setStatus] = useState("");
    const [eventCode, setEventCode] = useState("");
    const [layoutType, setLayoutType] = useState("");
    const [layoutTypeId, setLayoutTypeId] = useState("");
    const [details, setDetails] = useState([]);
    const [layoutTypes, setLayoutTypes] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [selectedType, setSelectedType] = useState([]);
    const [videoType, setVideoType] = useState("");

    var nameInp, titleInp, videoUrlInp, layoutTypeInp, videoTypeInp;

    useEffect(() => {
        nameInp = document.getElementById("name-input");
        titleInp = document.getElementById("title-input");
        videoUrlInp = document.getElementById("video-url-input");
        layoutTypeInp = document.getElementById("layout-type");
        videoTypeInp = document.getElementById("video-type");
        if (code) {
            setEventCode(code);
        }
    });

    useEffect(() => {
        getLayoutTypes();
        if (code) {
            getDetails(code);
        }
    }, [])

    const getDetails = async (event_code) => {
        setAlertMsg(true);
        try {
            const response = await getPresentationDetails(event_code);
            setDetails(response.data.data);
            setEventName(response.data.data.eventName);
            setEventCode(event_code);
            setMetaData(response.data.data.metaData);
            setMetaKey(response.data.data.metaKey);
            setTitle(response.data.data.pageTitle);
            setVideoUrl(response.data.data.videoSourcePath);
            setLayoutType(response.data.data.layoutType);
            setLayoutTypeId(response.data.data.layoutTypeId);
            setShowQuestions(response.data.data.showQuestions);
            setStatus(response.data.data.status);
            setVideoType(response.data.data.videoType);
            setAlertMsg(false);
        } catch (error) {
            setDetails([]);
            console.error(error);
            setAlertMsg(false);
        }
    };

    const getLayoutTypes = async () => {
        setAlertMsg(true);
        try {
            const response = await getLayouts();
            setLayoutTypes(response.data.data);
            setAlertMsg(false);
        } catch (error) {
            setDetails([]);
            console.error(error);
            setAlertMsg(false);
        }
    };

    const handleMetaKey = (val) => {
        setMetaKey(val);
    }

    const handleMetaData = (val) => {
        setMetaData(val);
    }

    const handleShowQuestions = (val) => {
        setShowQuestions(val);
    }

    const handleStatus = (val) => {
        setStatus(val);
    }

    const validateFields = () => {
        let pass = true;
        if (eventName.trim() == "") {
            setErrName(true);
            nameInp.classList.add("error-input-border");
            pass = false;
        }
        if (title == "") {
            setErrTitle(true);
            titleInp.classList.add("error-input-border");
            pass = false;
        }
        if (videoUrl == "") {
            setErrVideoUrl(true);
            videoUrlInp.classList.add("error-input-border");
            pass = false;
        }
        if (layoutTypeId == "") {
            setErrLayoutType(true);
            layoutTypeInp.classList.add("error-input-border");
            pass = false;
        }
        if (videoType == "" || videoType == null) {
            setErrVideoType(true);
            videoTypeInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const onChangeEventName = (val) => {
        if (errName == true) {
            nameInp.classList.remove("error-input-border");
            setErrName(false);
        }
        setEventName(val);
    }
    const onChangeTitle = (val) => {
        if (errTitle == true) {
            titleInp.classList.remove("error-input-border");
            setErrTitle(false);
        }
        setTitle(val);
    }
    const onChangeVideoUrl = (val) => {
        if (errVideoUrl == true) {
            videoUrlInp.classList.remove("error-input-border");
            setErrVideoUrl(false);
        }
        setVideoUrl(val);
    }

    const handleVideoType = (val) => {
        if (errVideoType == true) {
            videoTypeInp.classList.remove("error-input-border");
            setErrVideoType(false);
        }
        setVideoType(val);
    }

    const handleLayoutType = (event) => {
        if (errLayoutType == true) {
            layoutTypeInp.classList.remove("error-input-border");
            setErrLayoutType(false);
        }
        const selectedId = event.target.options[event.target.selectedIndex].id;
        const selectedValue = event.target.value;
        setLayoutTypeId(selectedId);
        setLayoutType(selectedValue);
    }

    const hideToast = () => {
        setShowToast(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            setAlertMsg(true);
            try {
                const response = await createPresentation({
                    eventName, title, videoUrl, metaKey, metaData, showQuestions, layoutTypeId, videoType
                });
                if (response.status) {
                    setAlertMsg(false);
                    setEventCode(response.data.data);
                    sessionStorage.setItem('eventCode', response.data.data);
                    getDetails(response.data.data);
                    if (layoutType === "ONE" || layoutType == "THREE") {
                        window.location.href = '/presentation/slides/' + response.data.data;
                    }
                    else if (layoutType === "TWO") {
                        window.location.href = '/presentation/topics/' + response.data.data;
                    }
                    else {
                        window.location.href = '/presentation/preview/' + response.data.data;
                    }
                } else {
                    setAlertMsg(false);
                }
            } catch (error) {
                setAlertMsg(false);
            }
        }

    }

    const handleUpdateSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            setAlertMsg(true);
            try {
                const response = await updatePresentation({
                    eventCode, eventName, title, videoUrl, metaKey, metaData, showQuestions, layoutTypeId, status, videoType
                });

                if (response.status) {
                    setAlertMsg(false);
                    getDetails(eventCode);
                    if (layoutType === "ONE" || layoutType == "THREE") {
                        window.location.href = '/presentation/slides/' + eventCode;
                    }
                    else if (layoutType === "TWO") {
                        window.location.href = '/presentation/topics/' + eventCode;
                    }
                    else {
                        window.location.href = '/presentation/preview/' + eventCode;
                    }
                } else {
                    setAlertMsg(false);
                }
            } catch (error) {
                setAlertMsg(false);
            }
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <form id="kt_modal_new_target_form" className="mb-8 form fv-plugins-bootstrap5 fv-plugins-framework"
                    onSubmit={eventCode ? handleUpdateSubmit : handleSubmit}>
                    <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                        <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                    Add Presentation
                                </h1>
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                    <li className="breadcrumb-item text-muted">
                                        <Link to="/presentation" className="text-muted text-hover-primary">Presentations</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                    </li>
                                    <li className="breadcrumb-item text-muted">Configuration Details</li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center gap-2 gap-lg-3 text-white">
                                {layoutType === 'ONE' && <Link to={"/presentation/slides/" + eventCode} className={!eventCode ? "btn btn-sm fw-bold btn-primary d-none" : "btn btn-sm fw-bold btn-primary"} >
                                    Next <i className="fa fa-arrow-right"></i>
                                </Link>
                                }
                                {layoutType === 'TWO' && <Link to={"/presentation/topics/" + eventCode} className={!eventCode ? "btn btn-sm fw-bold btn-primary d-none" : "btn btn-sm fw-bold btn-primary"} >
                                    Next <i className="fa fa-arrow-right"></i>
                                </Link>
                                }
                                {layoutType === 'THREE' && <Link to={"/presentation/slides/" + eventCode} className={!eventCode ? "btn btn-sm fw-bold btn-primary d-none" : "btn btn-sm fw-bold btn-primary"} >
                                    Next <i className="fa fa-arrow-right"></i>
                                </Link>
                                }
                                {layoutType === 'FOUR' && <Link to={"/presentation/preview/" + eventCode} className={!eventCode ? "btn btn-sm fw-bold btn-primary d-none" : "btn btn-sm fw-bold btn-primary"} >
                                    Next <i className="fa fa-arrow-right"></i>
                                </Link>
                                }
                            </div>

                        </div>
                    </div>
                    <div id="kt_app_content" className="app-content  flex-column-fluid ">
                        <div id="kt_app_content_container" className="app-container  container-xxl ">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0 pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bold fs-3 mb-1">Presentation Details</span>
                                    </h3>
                                    {showToast && <AlertSuccess show={showToast} message={"Configuration Details Updated Successfully"} hideToast={hideToast} />}
                                </div>

                                <div className="card-body py-3">
                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            showConfirm={false}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="required">Presentation Name</span></label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" className="form-control"
                                                    value={eventName} id="name-input"
                                                    onChange={(e) => onChangeEventName(e.target.value)}
                                                    name="eventName" placeholder="Presentation Name" />
                                                <span className="input-pre-text"><small>&ensp;</small></span>
                                                {errName == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Presentation Name.</small></span> : null}
                                            </div>
                                        </div>
                                    </div>

                                   



                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="required">Page Title</span></label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" className="form-control"
                                                    value={title} id="title-input"
                                                    onChange={(e) => onChangeTitle(e.target.value)}
                                                    name="title" placeholder="Page Title" />
                                                <span className="input-pre-text"><small>&ensp;</small></span>
                                                {errTitle == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Page Title.</small></span> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                <span className="required">Video Type</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <select name="videoType" className="form-control input-md" id="video-type"
                                                    value={videoType} onChange={(e) => handleVideoType(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="mp4">Mp4</option>
                                                    <option value="youtube">Youtube</option>
                                                </select>
                                                <span className="input-pre-text"><small>&ensp;</small></span>
                                                {errVideoType == true ? <span className="input-error-text ml-1rem" ><small>Please Select Video Type.</small></span> : null}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="required">Video Link</span></label>
                                            </div>
                                            <div className="col-md-6">

                                                <input type="text" className="form-control"
                                                    value={videoUrl} id="video-url-input"
                                                    onChange={(e) => onChangeVideoUrl(e.target.value)}
                                                    name="videoUrl" placeholder="Video Link" />
                                                <span className="input-pre-text"><small>&ensp;</small></span>
                                                {errVideoUrl == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Video Link.</small></span> : null}

                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    Meta Keywords</label>
                                            </div>
                                            <div className="col-md-6">
                                                <textarea className="form-control" rows="6" cols="70" name="metakey" placeholder="Enter keywords for search engines" value={metaKey}
                                                    onChange={(e) => handleMetaKey(e.target.value)} >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    Meta Data</label>
                                            </div>
                                            <div className="col-md-6">
                                                <textarea rows="6" cols="70" name="metadata" id="metadata" className="form-control" placeholder="Enter description for your presentation page" value={metaData}
                                                    onChange={(e) => handleMetaData(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    <span className="required">Choose a layout</span></label>
                                            </div>
                                            <div className="col-md-3">
                                                <select name="layoutType" className="form-control input-md" id="layout-type"
                                                    value={layoutType} onChange={handleLayoutType}>
                                                    <option value="">Select</option>
                                                    {layoutTypes?.map((item, i) => {
                                                        return (
                                                            <option id={item.layoutTypeId} value={item.name}>{item.title}</option>
                                                        );
                                                    })}
                                                </select>
                                                <span className="input-pre-text"><small>&ensp;</small></span>
                                                {errLayoutType == true ? <span className="input-error-text ml-1rem" ><small>Please Select Layout Type.</small></span> : null}
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    Allow Slides</label>
                                            </div>
                                            <div className="col-md-3">
                                                <select name="showSlides" className="form-control input-md"
                                                    value={showSlides} onChange={(e) => handleShowSlides(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="Y">Enable</option>
                                                    <option value="N">Disable</option>
                                                </select>


                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    Show Contents</label>
                                            </div>
                                            <div className="col-md-3">
                                                <select name="showTopics" className="form-control input-md"
                                                    value={showTopics} onChange={(e) => handleShowTopics(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="Y">Enable</option>
                                                    <option value="N">Disable</option>
                                                </select>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-8">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                    Enable Q&A</label>
                                            </div>
                                            <div className="col-md-3">
                                                <select name="showQuestions" className="form-control input-md"
                                                    value={showQuestions} onChange={(e) => handleShowQuestions(e.target.value)}>
                                                    <option value="">Select</option>
                                                    <option value="Y">Enable</option>
                                                    <option value="N">Disable</option>
                                                </select>


                                            </div>
                                        </div>
                                    </div> */}
                                    {eventCode ?
                                        <div className="form-group mb-8">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                        Status</label>
                                                </div>
                                                <div className="col-md-3">
                                                    <select name="status" className="form-control input-md"
                                                        value={status} onChange={(e) => handleStatus(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="D">Draft</option>
                                                        <option value="P">Publish</option>
                                                    </select>


                                                </div>
                                            </div>
                                        </div>
                                        : <></>}
                                    <div className="form-group col-md-6 mb-8">
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-sm fw-bold btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Configuration;

