import { Alert } from "react-bootstrap";

function AlertSuccess(props) {
    return (
        <>
            <Alert variant="success" onClose={props.hideToast} dismissible style={{ width: "400px", float: "right" }}>
                {props.message}
            </Alert>
        </>
    )
}
export default AlertSuccess;


