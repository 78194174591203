import React from 'react';
import { Alert } from 'react-bootstrap';
import '../Login/Login.css';

function ViewDraft() {
   
    return (
        <>
            <div id="kt_app_toolbar" class="container-xxl">
            <div className="row mt-8">
            <div className="col-md-4"></div>
            <div className="col-md-4 text-center">
            <div className="mb-30">
              <p className="logo-text text-black">Slidesmap</p>
            </div>
             <Alert className="login-alerts" variant="danger">
             <h3>This Presentation Link is not a valid one.</h3>
             <p>Please check and try again.</p>
             </Alert>
             </div>
             </div>
            </div>
        </>
    );
}

export default ViewDraft;
