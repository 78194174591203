import React from 'react';
import { Modal } from 'react-bootstrap';


function SuccessModal(props) {


    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
               <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p className='swal-text'>
                        {props.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={props.nextscreen}>Next</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default SuccessModal;


