import React from 'react';
import { Modal } from 'react-bootstrap';

function CopyMessageModal(props) {
  
    return (
        <>
            <Modal
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
                <Modal.Header closeButton style={{borderBottom:"none"}}>
                    <span className="fs-18">{props.message}</span>
                </Modal.Header>
            </Modal>
        </>
    )
}
export default CopyMessageModal;


