import React,{ useState } from 'react';
import { Modal } from 'react-bootstrap';
import MySwiper from './MySwiper';


function PreviewModal(props) {

    return (
        <>
            <Modal
                {...props}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Slides</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
              <MySwiper data={props.data}/>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default PreviewModal;


