import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function TopicsCard(props) {
    const [topicsData, setTopicsData] = useState([]);
    const [activeTopicIndex, setActiveTopicIndex] = useState(0);

    useEffect(() => {
            setTopicsData(props.data);
            setActiveTopicIndex(props.selectedIndex);
    });

  
    const selectTopic = (id, index, flag) => {
        setActiveTopicIndex(index);
        props.id(id);
        props.index(index);
        props.flag(flag);

    }

    return (
        <>

            <>
                <div class="card mt-8">
                    <div class="card-header card-header-stretch">
                        <div class="card-toolbar m-0">
                            <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
                                <li class="nav-item" role="presentation"><a id="contents_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#contentsTab" aria-selected="false" tabindex="-1"> Topics </a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">

                            <div id="contentsTab" class="card-body p-0" role="tabpanel" aria-labelledby="contents_tab">
                                {topicsData?.map((item, index) => {
                                    return (
                                        <div key={index} className={activeTopicIndex == index ? "topic-active" : "topic"}
                                            >
                                                <Link onClick={() => selectTopic(item.eventTopicId, index, true)} className={activeTopicIndex == index ? "topic-active" : "topic"}>
                                            <i class="fa fa-angle-right"></i>
                                            <span style={{ padding: "10px", fontSize: "16px" }}>{item.content}</span></Link>
                                        </div>
                                    );
                                })}
                            </div>


                        </div>
                    </div>

                </div>

            </>
        </>
    );
}

export default TopicsCard;