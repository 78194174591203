import React from 'react';
import { GOOGLE_AUTH_URL } from '../../Constants';
import googleIcon from "../../assets/images/google-icon.svg";

function SocialSignup() {
    return (

        <div class="row g-3 mb-9">
            <div class="col-md-12">
                <a href={GOOGLE_AUTH_URL} class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                    <img alt="Logo" src={googleIcon} class="h-15px me-3" />
                    Sign up with Google
                </a>
            </div>
        </div>

    );
}

export default SocialSignup;
