import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function EmbedModal(props) {
    //const [copySuccess, setCopySuccess] = useState('');
    const [embedcode, setEmbedcode] = useState('');
    useEffect(() => {
        const code = '<iframe src="'+props.shareablelink+'" width="600" height="400" frameborder="0" allowfullscreen></iframe>';
        setEmbedcode(code);
    });
   
    return (
        <>
            <Modal
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              >
                <Modal.Header closeButton>
                    <span className="fs-18">Embed Video</span>
                </Modal.Header>
                <Modal.Body>
                    <span className="fs-16">
                        {embedcode}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={props.handleEmbedCopy}>Copy</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EmbedModal;


