import React, { useState, useEffect } from 'react';
import './Login.css';
import RightBanner from './LeftBanner';
import { Link } from 'react-router-dom';
import { userSignup } from '../../services/user';
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "react-bootstrap";
import Hide from "../../assets/images/hide.svg";
import Show from "../../assets/images/show.svg";
import loading from "../../assets/images/loader_grey.gif";
import SocialSignup from './SocialSignup';


export default function Signup() {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [alertMsg, setAlertMsg] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [errFirstname, setErrFirstname] = useState(false);
    const [errLastname, setErrLastname] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errPassword, setErrPassword] = useState(false);
    const [showPswd, setShowPswd] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [successMsg, setSuccessMsg] = useState();
    const [alertSuccess, setAlertSuccess] = useState(false);

    const showPswdHandler = () => {
        setShowPswd(!showPswd);
    }


    var firstnameInp, lastnameInp, emailInp, passwordInp;

    useEffect(() => {
        firstnameInp = document.getElementById("firstname-input");
        lastnameInp = document.getElementById("lastname-input");
        emailInp = document.getElementById("email-input");
        passwordInp = document.getElementById("password-input");
    });



    const validateFields = () => {
        let pass = true;
        if (firstname.trim() == "") {
            setErrFirstname(true);
            firstnameInp.classList.add("error-input-border");
            pass = false;
        }
        if (lastname.trim() == "") {
            setErrLastname(true);
            lastnameInp.classList.add("error-input-border");
            pass = false;
        }
        if (email.trim() == "") {
            setErrEmail(true);
            emailInp.classList.add("error-input-border");
            pass = false;
        }
        if (password == "") {
            setErrPassword(true);
            passwordInp.classList.add("error-input-border");
            pass = false;
        }
        return pass;
    }

    const onChangeFirstname = (val) => {
        if (errFirstname == true) {
            firstnameInp.classList.remove("error-input-border");
            setErrFirstname(false);
        }
        setFirstname(val);
    }
    const onChangeLastname = (val) => {
        if (errLastname == true) {
            lastnameInp.classList.remove("error-input-border");
            setErrLastname(false);
        }
        setLastname(val);
    }
    const onChangeEmail = (val) => {
        if (errEmail == true) {
            emailInp.classList.remove("error-input-border");
            setErrEmail(false);
        }
        setEmail(val);
    }
    const onChangePassword = (val) => {
        if (errPassword == true) {
            passwordInp.classList.remove("error-input-border");
            setErrPassword(false);
        }
        setPassword(val);
    }


    const handleSubmit = async e => {
        e.preventDefault();
        if (!validateFields()) {
            return;
        } else {
            try {
                setAlertMsg(true);
                const response = await userSignup({ firstname, lastname, email, password });
                if (response.status) {
                    setAlertMsg(false);
                    setFirstname("");
                    setLastname("");
                    setEmail("");
                    setPassword("");
                    setAlertError(false);
                    setAlertSuccess(true);
                    setSuccessMsg("Registered Successfully");
                } else {
                    setAlertMsg(false);
                    setErrMsg(response.message);
                    setAlertError(true);
                }
            } catch (error) {
                setErrMsg("Internal Server Error");
                setAlertMsg(false);
                setAlertError(true);
            }
        }

    }

    return (
        <>
            <div className="d-flex flex-column flex-root login-bg">
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <RightBanner />
                    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 order-1 order-lg-2 right-text">
                        <div class="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            <div class="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                <div class="d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-20">
                                    <Alert className="login-alerts" show={alertError} onClose={() => setAlertError(false)} variant="danger" dismissible>
                                        {errMsg}
                                    </Alert>
                                    <Alert className="login-alerts" show={alertSuccess} onClose={() => setAlertSuccess(false)} variant="success" dismissible>
                                        {successMsg}
                                    </Alert>
                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            timeout={10000}
                                            showConfirm={false}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <form class="form w-100" id="kt_sign_up_form" onSubmit={handleSubmit}>
                                        <div class="text-center mb-11">
                                            <h1 class="text-gray-900 fw-bolder mb-3">
                                                Sign Up
                                            </h1>
                                        </div>

                                        <SocialSignup />
                                        <div class="separator separator-content my-14"><span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span></div>

                                        <div class="fv-row mb-2">
                                            <input type="text" value={firstname} id="firstname-input"
                                                onChange={(e) => onChangeFirstname(e.target.value)} placeholder="First Name" name="firstname" autocomplete="off" class="form-control bg-transparent" />
                                            <span className="input-pre-text"><small>&ensp;</small></span>
                                            {errFirstname == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Firstname.</small></span> : null}
                                        </div>
                                        <div class="fv-row mb-2">
                                            <input type="text" value={lastname} id="lastname-input"
                                                onChange={(e) => onChangeLastname(e.target.value)} placeholder="Last Name" name="lastname" autocomplete="off" class="form-control bg-transparent" />
                                            <span className="input-pre-text"><small>&ensp;</small></span>
                                            {errLastname == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Lastname.</small></span> : null}
                                        </div>
                                        <div class="fv-row mb-2">
                                            <input type="email" value={email} id="email-input"
                                                onChange={(e) => onChangeEmail(e.target.value)} placeholder="Email" name="email" autocomplete="off" class="form-control bg-transparent" />
                                            <span className="input-pre-text"><small>&ensp;</small></span>
                                            {errEmail == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Email.</small></span> : null}
                                        </div>

                                        <div class="fv-row mb-2" data-kt-password-meter="true">
                                            <div class="mb-1">
                                                <div class="position-relative mb-3">
                                                    <input class="form-control bg-transparent" value={password} id="password-input"
                                                        onChange={(e) => onChangePassword(e.target.value)} type={showPswd ? "text" : "password"} placeholder="Password" name="password" autocomplete="off" />

                                                    <div className="show-hide-pswd-eye" onClick={() => { showPswdHandler() }}>
                                                        {showPswd ? <img src={Hide} /> : <img src={Show} />}
                                                    </div>
                                                    <span className="input-pre-text"><small>&ensp;</small></span>
                                                    {errPassword == true ? <span className="input-error-text ml-1rem" ><small>Please Enter Password.</small></span> : null}
                                                </div>

                                            </div>

                                        </div>

                                        <div class="d-grid mb-10">
                                            <button type="submit" id="kt_sign_up_submit" class="btn btn-primary">

                                                <span class="indicator-label">
                                                    Sign up</span>

                                                <span class="indicator-progress">
                                                    Please wait...    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        </div>

                                        <div class="text-gray-500 text-center fw-semibold fs-6">
                                            Already have an Account?
                                            <Link to="/login" className="link-primary fw-semibold"> Sign in
                                            </Link>

                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
