import { LOGIN,GET_TOKEN } from "../Constants";

export function loginUser(credentials) {
 return fetch(LOGIN, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}
export function getToken(request) {
  return fetch(GET_TOKEN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
 }