import axios from "axios";
import { API_BASE_URL } from "../Constants";

 const axiosInterceptor = axios.create({
  baseURL: API_BASE_URL,
   headers: {
    "Content-Type": "application/json",
  },
});

axiosInterceptor.interceptors.request.use(
  (config) => {
     // Do something before request is sent
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    const token = userToken.token;
    if (token) {
     // config.headers.accessToken = token;
      config.headers['Authorization'] = 'Bearer ' + token
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
   function (error) {
       // Handle request error
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

axiosInterceptor.interceptors.response.use(
   function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
   function (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ERR_NETWORK') {
        console.error('Network error: Check your internet connection.');
      } else if (error.response) {
        console.error(`Server responded with status ${error.response.status}`);
      } else if (error.request) {
        console.error('No response received');
      } else {
        console.error('Request error:', error.message);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;