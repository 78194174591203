import React,{ useState } from 'react';
import { Modal } from 'react-bootstrap';


function FileUploadModal(props) {

    const [file, setFile] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");

    const handleFileChange = (event) => {
        if(event.target.files[0].size > 10 * 1024 * 1024){
            setErrorMsg("File exceeds maximum size 10MB");
        }else{
            setErrorMsg("");
        setFile(event.target.files[0]);
        props.file(event.target.files[0]);
        }
    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body className='text-center'>
                <label htmlFor="fileInput" className="file-label" style={{display: "inline"}}>
                                                <i className="fas fa-cloud-upload-alt"></i>
                                                Choose a file
                                            </label>
                <input type="file" accept=".pdf, .pptx" onChange={handleFileChange} /><br></br>
                <span className='error'>{errorMsg}</span>
                </Modal.Body>
                <Modal.Footer>
                    <button className={errorMsg?"btn btn-primary disabled":"btn btn-primary"} onClick={props.cancel}>Upload</button>
                    <button className="btn btn-secondary" onClick={props.onHide}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default FileUploadModal;


