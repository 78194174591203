import React, { useState  } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faXTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCode } from '@fortawesome/free-solid-svg-icons';


function ShareLinkModal(props) {
    const [shareableLink, setShareableLink] = useState(props.shareablelink);

    const shareToWhatsApp = () => {
        const message = 'Check this out : ' + shareableLink;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = 'https://api.whatsapp.com/send?text='+encodedMessage;
        window.open(whatsappUrl, '_blank');
    };

    const shareViaEmail = () => {
        const subject = 'Check this out!';
        const body = 'Check this link: ' + shareableLink;
        const encodedMessage = encodeURIComponent(body);
        const mailtoUrl = 'mailto:?subject='+encodeURIComponent(subject)+'&body='+encodedMessage;
        window.open(mailtoUrl, '_blank');
    };


    const shareOnFacebook = () => {
        const encodedMessage = encodeURIComponent(shareableLink);
        const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u='+encodedMessage;
        window.open(facebookUrl, '_blank');
    };

    const handleLinkedInShare = () => {
        const encodedMessage = encodeURIComponent(shareableLink);
        const encodedText = encodeURIComponent('Check out this link');
        const linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&url='+encodedMessage+'&title='+encodedText;
        window.open(linkedInUrl, '_blank');
      };
    
      const handleTwitterShare = () => {
        const encodedMessage = encodeURIComponent(shareableLink);
        const encodedText = encodeURIComponent('Check out this link');
        const twitterUrl = 'https://twitter.com/intent/tweet?url='+encodedMessage+'&text='+encodedText;
        window.open(twitterUrl, '_blank');
      };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <span className="fs-18">Share Presentation Link</span>
                </Modal.Header>

                <Modal.Body>
                    <span className='fs-18'>Share</span>
                    <div className="d-flex align-items-start mt-3">
                        <Card className="text-center shareIconsCard" onClick={props.handleEmbed}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faCode} className="shareIcons" />
                                </Card.Title>
                                Embed
                            </Card.Body>
                        </Card>
                        <Card className="text-center shareIconsCard" onClick={shareToWhatsApp}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faWhatsapp} className="shareIcons whatsappIcon" />
                                </Card.Title>
                                WhatsApp
                            </Card.Body>
                        </Card>
                        <Card className="text-center shareIconsCard" onClick={shareViaEmail}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                   <FontAwesomeIcon icon={faEnvelope} className="shareIcons emailIcon" />
                                </Card.Title>
                                Email
                            </Card.Body>
                        </Card>
                        <Card className="text-center shareIconsCard" onClick={shareOnFacebook}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faFacebook} className="shareIcons facebookIcon" />
                                </Card.Title>
                                Facebook
                            </Card.Body>
                        </Card>
                        <Card className="text-center shareIconsCard" onClick={handleTwitterShare}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faXTwitter} className="shareIcons twitterIcon" />
                                </Card.Title>
                                X
                            </Card.Body>
                        </Card>
                        <Card className="text-center shareIconsCard" onClick={handleLinkedInShare}>
                            <Card.Body className="shareIconsCardBody">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faLinkedin} className="shareIcons facebookIcon" />
                                </Card.Title>
                                LinkedIn
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='copyLinkDiv mt-8'>
                        <input className="border-none col-md-9" type="text" value={shareableLink} readOnly />
                        <Button variant="primary" className="copyBtn col-md-2" onClick={props.handleCopy}>Copy</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ShareLinkModal;


