import React, { useState, useEffect, useRef } from 'react';
import loading from "../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { API_BASE_URL, GET_PRESENTATION_DETAILS } from '../../Constants.js';
import Footer from './Footer.js';
import Header from './Header.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Import Video.js styles
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

function ViewTwo(props) {
    const code = props.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [eventName, setEventName] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [videoType, setVideoType] = useState("");
    const [details, setDetails] = useState([]);
    const [status, setStatus] = useState(false);
    const [topicsData, setTopicsData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [activeTopicIndex, setActiveTopicIndex] = useState(0);

    // current time of the video in array. The first value represents the minute and the second represents seconds.
    const [currentMinutes, setCurrentMinutes] = useState(0);
    const [currentSeconds, setCurrentSeconds] = useState(0);
    //current time of the video in seconds
    const [currentTimeSec, setCurrentTimeSec] = useState();
    // total duration of the video in the array. The first value represents the minute and the second represents seconds.
    const [duration, setDuration] = useState([0, 0]);
    // current duration of the video in seconds
    const [durationSec, setDurationSec] = useState();
    const [isPlaying, setIsPlaying] = useState(false);

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        if (code) {
            setEventCode(code);
        }
    });

    useEffect(() => {
        if (code) {
            setFlag(true);
            getDetails(code);
        }
    }, []);

    const getDetails = async (event_code) => {
        setAlertMsg(true);
        try {
            const response = await axios.get(API_BASE_URL + GET_PRESENTATION_DETAILS + event_code);
            if (response.data) {
                setAlertMsg(false);
                setDetails(response.data.data);
                setStatus(response.data.data.status);
                setVideoUrl(response.data.data.videoSourcePath);
                setTopicsData(response.data.data.topicDetails);
                setEventName(response.data.data.eventName);
                if(response.data.data.videoType!=null){
                    setVideoType(response.data.data.videoType);
                }else{
                    setVideoType("mp4");
                }
                
            } else {
                setAlertMsg(false);
            }
        } catch (error) {
            setDetails([]);
            setAlertMsg(false);
        }
    };


    useEffect(() => {
        if (videoRef.current != null) {
            const { min, sec } = sec2Min(videoRef.current.duration);
            setDurationSec(videoRef.current.duration);
            setDuration([min, sec]);
            const interval = setInterval(() => {
                const { min, sec } = sec2Min(videoRef.current.currentTime);
                setCurrentTimeSec(videoRef.current.currentTime);
                setCurrentMinutes(min);
                setCurrentSeconds(sec);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isPlaying]);

    const sec2Min = (sec) => {
        var min = Math.floor(sec / 60);
        var secRemain = Math.floor(sec % 60);
        if (min < 10) {
            min = '0' + min;
        } else {
            min = '' + min;
        }
        if (secRemain < 10) {
            secRemain = '0' + secRemain;
        } else {
            secRemain = '' + secRemain;
        }
        return {
            min: min,
            sec: secRemain,
        };
    };

    useEffect(() => {
        // Ensure that Video.js player is initialized only once
        if (videoRef.current) {
            playerRef.current = videojs(videoRef.current, {
                controls: true,
                autoplay: false,
                preload: 'auto',
            });

            if (videoUrl) {
                playerRef.current.src({ src: videoUrl, type: 'video/'+videoType });
            }

            // Event listener for timeupdate
            const handleTimeUpdate = () => {
                const currentTime = playerRef.current.currentTime();
                const activeTopicIndex = topicsData.findIndex(item => currentTime >= item.startTimeInSec && currentTime <= item.endTimeInSec);
                if (activeTopicIndex == -1) {
                    setActiveTopicIndex(topicsData.length - 1);
                } else {
                    setActiveTopicIndex(activeTopicIndex);
                }
            };

            playerRef.current.on('timeupdate', handleTimeUpdate);
        }
        // Cleanup function to dispose of the player
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [videoUrl]); // Dependency on videoUrl to re-initialize when it changes

    const setVideoTime = (time) => {
        if (playerRef.current) {
            playerRef.current.currentTime(time);
        }
    };

    const selectTopic = (id, index, flag) => {
        setActiveTopicIndex(index);
        const topic = topicsData.find(item => item.eventTopicId === id);
        if (flag) {
            setVideoTime(topic.startTimeInSec);
        }
    }

    return (
        <>
           
                                    {alertMsg ?
                                        <SweetAlert
                                            title=""
                                            timeout={10000}
                                            style={{ width: "200px", height: "100px" }}
                                        > <img src={loading} width="50px" height="50px" />
                                        </SweetAlert>
                                        : <></>}
                                    <div className="row">
                                        <div className="col-md-7 col-sm-12 videoDiv video-js-responsive-container videoContainer order-1">
                                            <div id="videoDiv" className="box">
                                                {videoUrl ? <video ref={videoRef} className="video-js"
                                                    preload="auto"
                                                    controls={true}
                                                    style={{ width: "100%", height: "415px", backgroundColor: "unset" }} /> : ""}
                                            </div>
                                        </div>
                                            <div className="col-md-5 col-sm-12 slidesDiv box order-2" style={{ padding: "20px" }}> 
                                                <h4 style={{ margin: "10px" }}>Topics</h4>
                                                {topicsData?.map((item, index) => {
                                                    return (
                                                        <div key={index} className={activeTopicIndex == index ? "topic-active" : "topic"}
                                                            onClick={() => selectTopic(item.eventTopicId, index, true)}>
                                                            <i class="fa fa-angle-right"></i>
                                                            <span style={{ padding: "10px", fontSize: "16px" }}>{item.content}</span>
                                                        </div>
                                                    );
                                                })}
                                                </div>
                                    </div>

        </>
    );
}

export default ViewTwo;