function Footer() {
    return (
        <>
            <div id="kt_app_footer" className="app-footer ">
                <div className="app-container container-xxl py-3 text-center">
                    <div className="text-gray-900 order-2 order-md-1">
                        <span className="text-muted fw-semibold me-1">2024©</span>
                        <a href="#" target="_blank" className="text-gray-800 text-hover-primary">Slidesmap</a>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Footer;