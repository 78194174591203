import React from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const MySwiper = (props) => {
  return (
    <Swiper
      modules={[Navigation,Pagination]}
      navigation
      pagination={{
        type:"fraction",
        clickable:true
      }}
      spaceBetween={50}
    >
       {props.data.map((slide, index) => (
        <SwiperSlide key={index}><img src={slide.imgPath} alt={`Slide ${index+1}`}/></SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MySwiper;
