import React, { useState, useEffect, useRef } from 'react';
import { deleteEventTopic, getTopicDetails, getVideoDetails, updateTopics } from '../../../services/presentation';
import loading from "../../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import AlertSuccess from '../../AlertSuccess';
import SuccessModal from './SuccessModal';
import WarningModal from './WarningModal';


function Topics() {
    const params = useParams();
    const code = params.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [videoType, setVideoType] = useState("");
    const [details, setDetails] = useState([]);
    const [status, setStatus] = useState(false);
    const [topicsData, setTopicsData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedTopicId, setSelectedTopicId] = useState(0);
    const [startBtn, setStartBtn] = useState(false);
    const [endBtn, setEndBtn] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [currentMinutes, setCurrentMinutes] = useState(0);
    const [currentSeconds, setCurrentSeconds] = useState(0);
    const [showSlides, setShowSlides] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [layoutType, setLayoutType] = useState("");
    const [showNext, setShowNext] = useState(false);
    const [topicId, setTopicId] = useState();
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (code) {
            setEventCode(code);
        }
    });

    useEffect(() => {
        if (code) {
            getVideo(code);
            getDetails(code);
        }
    }, []);

    const getVideo = async (event_code) => {
        try {
            const response = await getVideoDetails(event_code);
            if (response.data) {
                setVideoUrl(response.data.data.videoSourcePath);
                setShowSlides(response.data.data.showSlides);
                setLayoutType(response.data.data.layoutType);
                if(response.data.data.videoType!=null){
                    setVideoType(response.data.data.videoType);
                }else{
                    setVideoType("mp4");
                }
            }
        } catch (error) {
            setDetails([]);
        }
    };

    const getDetails = async (event_code) => {
        try {
            const response = await getTopicDetails(event_code);
            if (response.data) {
                setDetails(response.data.data);
                setStatus(response.data.data.status);
                setTopicsData(response.data.data.list);
                setFlag(true);
            }
        } catch (error) {
            setDetails([]);
        }
    };

    const updateStartTime = (id) => {
        setStartBtn(false);
        setEndBtn(true);
        var time = "00:" + currentMinutes + ":" + currentSeconds;
        console.log(currentMinutes);
        setTopicsData((prevList) =>
            prevList.map((item, i) =>
                i === id ? { ...item, startTime: time } : item
            )
        );
    };

    const updateEndTime = (id) => {
        setEndBtn(false);
        var time = "00:" + currentMinutes + ":" + currentSeconds;
        setTopicsData((prevList) =>
            prevList.map((item, i) =>
                i === id ? { ...item, endTime: time } : item
            )
        );
    };

    const onChangeContentName = (val, id) => {
        setTopicsData((prevList) =>
            prevList.map((item, i) =>
                i === id ? { ...item, content: val } : item
            )
        );

    }
    const onChangeStartTime = (val, id) => {
        setTopicsData((prevList) =>
            prevList.map((item, i) =>
                i === id ? { ...item, startTime: val } : item
            )
        );
    }
    const onChangeEndTime = (val, id) => {
        setTopicsData((prevList) =>
            prevList.map((item, i) =>
                i === id ? { ...item, endTime: val } : item
            )
        );
    }

    const addRow = () => {
        const newRow = { content: '', startTime: '', endTime: '' };
        setTopicsData([...topicsData, newRow]);
    };

    const handleUpdateSubmit = async e => {
        e.preventDefault();
        setAlertMsg(true);
        const request = {
            "eventCode": eventCode,
            "topicDetails": topicsData
        }
        try {
            const response = await updateTopics(request);
            if (response.status) {
                setShowNext(true);
                setAlertMsg(false);
                getDetails(code);
            } else {
                setAlertMsg(false);
            }
        } catch (error) {
            setAlertMsg(false);
            console.log(error);
        }
    }
    const getMinutes = (min) => {
        setCurrentMinutes(min);
    }

    const getSeconds = (sec) => {
        setCurrentSeconds(sec);
    }

    const hideToast = () => {
        setShowToast(false);
    }

    const handleWarningModal = () => {
        setShowWarning(false);
      }

    const removeTopic = async () => {
        setShowWarning(false);
        setAlertMsg(true);
        try {
            const response = await deleteEventTopic(topicId);
            if (response.status) {
                setAlertMsg(false);
            } else {
                setAlertMsg(false);
            }
            getDetails(code);
        } catch (error) {
            setAlertMsg(false);
            console.log(error);
        }
      }

    const deleteTopic = async (id, index) => {
        if (id) {
            setShowWarning(true);
            setTopicId(id);
        } else {
            setTopicsData(topicsData.filter((row, i) => i !== index));
        }
    }

    const handleNextModal = () => {
        setShowNext(false);
    }

    const nextScreen = () => {
        setShowNext(false);
        window.location.href = '/presentation/preview/' + eventCode;
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                    <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                Add Presentation
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/presentation" className="text-muted text-hover-primary">Presentations</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Chapters</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {layoutType === 'TWO' &&  <Link to={"/presentation/configuration/" + eventCode} className={"btn btn-sm fw-bold btn-secondary"} >
                        <i className="fa fa-arrow-left"></i> Back
                                    </Link>
                            }
                             {layoutType === 'THREE' &&  <Link to={"/presentation/slides/" + eventCode} className={"btn btn-sm fw-bold btn-secondary"} >
                        <i className="fa fa-arrow-left"></i> Back
                                    </Link>
                            }
                           
                            <Link to={"/presentation/preview/" + eventCode} className={status ? "btn btn-sm fw-bold btn-primary text-white" : "btn btn-sm fw-bold btn-primary text-white disabled"}>
                                Next <i className="fa fa-arrow-right"></i>
                            </Link>
                        </div>

                    </div>
                    
                </div>
                <div className='container-fluid'>
                {showToast &&<AlertSuccess show={showToast} message={" Chapter Details Updated Successfully"} hideToast={hideToast}/>}
                </div>
                <div id="kt_app_content" className="app-content  flex-column-fluid ">


                    <div id="kt_app_content_container" className="app-container  container-xxl ">

                        <div className="card mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">

                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-3 mb-1">Video with Chapters</span>
                                </h3>
                                <button type="button" class="btn btn-success" id="addRow" onClick={addRow} style={{ float: "right",height: "40px",paddingTop: "10px" }}>Add</button>

                            </div>

                            <div className="card-body py-3">
                                {alertMsg ?
                                    <SweetAlert
                                        title=""
                                        showConfirm={false}
                                        style={{ width: "200px", height: "100px" }}
                                    > <img src={loading} width="50px" height="50px" />
                                    </SweetAlert>
                                    : <></>}
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 video-js-responsive-container videoContainer">
                                        <div id="videoDiv" className="mt-4">
                                            {videoUrl ? <VideoPlayer videoUrl={videoUrl} videoType={videoType} min={getMinutes} sec={getSeconds} /> : ""}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                <form onSubmit={handleUpdateSubmit}>
                                    <div className={flag ? "table-responsive" : "table-responsive divHide"} id="slide-details" >
                                        <table className="table table-row-bordered align-middle gs-0 gy-3 border mt-3">
                                            <thead>
                                                <tr className="fw-bold text-muted">
                                                    <th className="w-25px" style={{ paddingLeft: "10px" }}>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                            S.No
                                                        </div>
                                                    </th>
                                                    <th className="min-w-150px">Chapter</th>
                                                    <th className="min-w-120px">Start Time</th>
                                                    <th className="min-w-120px">End Time</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {topicsData?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ paddingLeft: "10px" }}>
                                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                    {/*<input className="form-check-input widget-13-check" type="radio" name="Topics"
                                                                        checked={checkedItems[i] || false} onChange={selectRow}
                                                                        value={i} />*/}
                                                                    {i + 1}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary fs-6" value={item.content}
                                                                    onChange={(e) => onChangeContentName(e.target.value, i)} required />
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.startTime}
                                                                    onChange={(e) => onChangeStartTime(e.target.value, i)}
                                                                    placeholder="hh:mm:ss" style={{ width: "65px" }} required />
                                                                <Link class="btn btn-success btn-table" onClick={() => updateStartTime(i)}><i className="fa fa-check"></i></Link>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.endTime}
                                                                    onChange={(e) => onChangeEndTime(e.target.value, i)}
                                                                    placeholder="hh:mm:ss" style={{ width: "65px" }} required />
                                                                <Link class="btn btn-success btn-table" onClick={() => updateEndTime(i)}><i className="fa fa-check"></i></Link>
                                                            </td>

                                                            <td className="text-end" style={{ paddingRight: "20px" }}>
                                                                <Link onClick={() => deleteTopic(item.eventTopicId, i)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                                    <i className="fa fa-trash"></i>
                                                                </Link>
                                                            </td>

                                                        </tr>

                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <button type="submit" className="btn btn-sm fw-bold btn-primary text-white" style={{ float: "right", marginBottom: "20px" }}>
                                        Submit</button>
                                    </div>
                                </form>
                                    </div>
                                </div>
                               {/* <button type="button" class="btn btn-small btn-success" id="addRow" onClick={addRow}>Add</button>
                                {showToast &&<AlertSuccess show={showToast} message={" Chapter Details Updated Successfully"} hideToast={hideToast}/>}
                                <form onSubmit={handleUpdateSubmit}>
                                    <div className={flag ? "table-responsive mt-8" : "table-responsive mt-8 divHide"} id="slide-details" >
                                        <button type="submit" className="btn btn-sm fw-bold btn-primary text-white" style={{ float: "right", marginBottom: "20px" }}>
                                            Submit</button>
                                        <table className="table table-row-bordered align-middle gs-0 gy-3 border mt-8">
                                            <thead>
                                                <tr className="fw-bold text-muted">
                                                    <th className="w-25px" style={{ paddingLeft: "10px" }}>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                            S.No
                                                        </div>
                                                    </th>
                                                    <th className="min-w-150px">Chapter</th>
                                                    <th className="min-w-140px">Start Time</th>
                                                    <th className="min-w-120px">End Time</th>
                                                    <th className="pe-4 min-w-200px text-end rounded-end">Delete</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {topicsData?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ paddingLeft: "10px" }}>
                                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                    {i + 1}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary fs-6" value={item.content}
                                                                    onChange={(e) => onChangeContentName(e.target.value, i)} required />
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.startTime}
                                                                    onChange={(e) => onChangeStartTime(e.target.value, i)}
                                                                    placeholder="hh:mm:ss" style={{ width: "80px" }} required />
                                                                <button class="btn btn-secondary btn-table" onClick={() => updateStartTime(i)}>Start</button>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.endTime}
                                                                    onChange={(e) => onChangeEndTime(e.target.value, i)}
                                                                    placeholder="hh:mm:ss" style={{ width: "80px" }} required />
                                                                <button class="btn btn-secondary btn-table" onClick={() => updateEndTime(i)}>End</button>
                                                            </td>

                                                            <td className="text-end" style={{ paddingRight: "20px" }}>
                                                                <button onClick={() => deleteTopic(item.eventTopicId, i)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                            </td>

                                                        </tr>

                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                {showNext && <SuccessModal show={showNext} nextscreen={nextScreen} onHide={handleNextModal} message={"Chapter Details Submitted Successfully.Click Next to Proceed"} />}
                {showWarning && <WarningModal topicId={topicId} show={showWarning} cancel={removeTopic} onHide={handleWarningModal} message={"Do you really want to delete?"} />}
            </div>

        </>
    );
}

export default Topics;