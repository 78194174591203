import React, { useState, useEffect } from 'react';
import { getSlideDetails, getVideoDetails, updateSlides } from '../../../services/presentation';
import loading from "../../../assets/images/loader_grey.gif";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { API_BASE_URL, REPLACE_SLIDES, UPLOAD_SLIDES } from '../../../Constants';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import VideoPlayer from './VideoPlayer.js';
import MySwiper from './MySwiper.js';
import WarningModal from './WarningModal.js';
import FileUploadModal from './FileUploadModal.js';
import AlertSuccess from '../../AlertSuccess.js';
import PreviewModal from './PreviewModal.js';
import SuccessModal from './SuccessModal.js';

function Slides() {
    const params = useParams();
    const code = params.eventCode;

    const [eventCode, setEventCode] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [details, setDetails] = useState([]);
    const [status, setStatus] = useState(false);
    const [slidesData, setSlidesData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedSlideId, setSelectedSlideId] = useState(0);
    const [startBtn, setStartBtn] = useState(false);
    const [endBtn, setEndBtn] = useState(false);
    const [currentMinutes, setCurrentMinutes] = useState(0);
    const [currentSeconds, setCurrentSeconds] = useState(0);
    const [alertMsg, setAlertMsg] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState('');
    const [slidesRequest, setSlidesRequest] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [showTopics, setShowTopics] = useState("");
    const [layoutType, setLayoutType] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [videoType, setVideoType] = useState("");

    useEffect(() => {
        if (code) {
            setEventCode(code);
        }
    });

    useEffect(() => {
        if (code) {
            setFlag(true);
            getVideo(code);
            getDetails(code);
        }
    }, []);


    const getVideo = async (event_code) => {
        try {
            const response = await getVideoDetails(event_code);
            if (response.data) {
                setVideoUrl(response.data.data.videoSourcePath);
                setShowTopics(response.data.data.showTopics);
                setLayoutType(response.data.data.layoutType);
                if(response.data.data.videoType!=null){
                    setVideoType(response.data.data.videoType);
                }else{
                    setVideoType("mp4");
                }
            }
        } catch (error) {
            setDetails([]);
        }
    };

    const getDetails = async (event_code) => {
        setAlertMsg(true);
        try {
            const response = await getSlideDetails(event_code);
            if (response.data) {
                setAlertMsg(false);
                setDetails(response.data.data);
                setStatus(response.data.data.status);
                setSlidesData(response.data.data.list);
                setSlidesRequest(copyKeys(response.data.data.list, ['eventSlideId', 'title', 'sequence', 'startTime', 'endTime']));
            } else {
                setAlertMsg(false);
            }
        } catch (error) {
            setDetails([]);
            setAlertMsg(false);
        }
    };


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleUpload = async () => {
        if (!file) {
            setMessage('Please select a PDF file to upload.');
            return;
        }
        const formData = new FormData();
        formData.append('slideFile', file);
        setUploading(true);
        setMessage('');
        setAlertMsg(true);
        try {
            const tokenString = sessionStorage.getItem('token');
            const userToken = JSON.parse(tokenString);
            const token = userToken.token;
            const response = await axios.post(API_BASE_URL + UPLOAD_SLIDES + eventCode, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.data) {
                getDetails(code);
            }
            setMessage('Upload successful: ' + response.data.message);
        } catch (error) {
            setAlertMsg(false);
            setMessage('Upload failed: ' + error.message);
        } finally {
            setUploading(false);
        }
    };

    const updateStartTime = (id) => {
        setStartBtn(false);
        setEndBtn(true);
        var time = "00:" + currentMinutes + ":" + currentSeconds;
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, startTime: time } : item
            )
        );
    };

    const updateEndTime = (id) => {
        setEndBtn(false);
        var time = "00:" + currentMinutes + ":" + currentSeconds;
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, endTime: time } : item
            )
        );
    };


    const onChangeName = (val, id) => {
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, title: val } : item
            )
        );

    }
    const onChangeSequence = (val, id) => {
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, sequence: val } : item
            )
        );
    }
    const onChangeStartTime = (val, id) => {
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, startTime: val } : item
            )
        );
    }
    const onChangeEndTime = (val, id) => {
        setSlidesRequest((prevList) =>
            prevList.map((item) =>
                item.eventSlideId === id ? { ...item, endTime: val } : item
            )
        );
    }
    const copyKeys = (array, keys) => {
        return array.map(item => {
            let newItem = {};
            keys.forEach(key => {
                if (item.hasOwnProperty(key)) {
                    newItem[key] = item[key];
                }
            });
            return newItem;
        });
    };

    const hideToast = () => {
        setShowToast(false);
    }

    const handleUpdateSubmit = async e => {
        e.preventDefault();
        setAlertMsg(true);
        const request = {
            "eventCode": eventCode,
            "slideDetailsRequest": slidesRequest
        }
        try {
            const response = await updateSlides(request);
            if (response.status) {
                setAlertMsg(false);
                setShowNext(true);
                getDetails(eventCode);
            }
        } catch (error) {
            setAlertMsg(false);
            console.log(error);
        }
    }

    const getMinutes = (min) => {
        setCurrentMinutes(min);
    }

    const getSeconds = (sec) => {
        setCurrentSeconds(sec);
    }

    const handleWarningModal = () => {
        setShowWarning(false);
    }

    const fileUpload = () => {
           setShowWarning(false);
           setShowFile(true);
    }

    const getNewFile = (file) => {
        setNewFile(file);
    }

    const replaceFile = () => {
        setShowWarning(true);
    }

    const showSlides = () => {
        setShowPreview(true);
    }

    const hidePreview = () => {
        setShowPreview(false);
    }

    const handleFileModal = () => {
        setShowFile(false);
    }

    const handleNextModal = () => {
        setShowNext(false);
    }

    const nextScreen = () => {
        setShowNext(false);
        if (layoutType === "ONE" || layoutType == "FOUR") {
            window.location.href = '/presentation/preview/' + eventCode;
        }
        else if (layoutType === "THREE") {
            window.location.href = '/presentation/topics/' + eventCode;
        }
    }

    const uploadNewFile = async () => {
        if(newFile.size > 10 * 1024 * 1024){
            alert("File exceeds maximum size 10MB");
        }else{
        setShowFile(false);
        const formData = new FormData();
        formData.append('slideFile', newFile);
        setAlertMsg(true);
        try {
            const tokenString = sessionStorage.getItem('token');
            const userToken = JSON.parse(tokenString);
            const token = userToken.token;
            const response = await axios.post(API_BASE_URL + REPLACE_SLIDES + eventCode, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                },
            });
            if (response.status) {
                getDetails(code);
            }
        } catch (error) {
            setAlertMsg(false);
        }
    }
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
                    <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                                Add Presentation
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/presentation" className="text-muted text-hover-primary">Presentations</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Slides</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to={"/presentation/configuration/" + eventCode} className="btn btn-sm fw-bold btn-secondary">
                                <i className="fa fa-arrow-left"></i> Back
                            </Link>
                            {status}
                            {layoutType === 'ONE' && <Link to={"/presentation/preview/" + eventCode} className={status ? "btn btn-sm fw-bold btn-primary" : "btn btn-sm fw-bold btn-primary disabled"} >
                                Next <i className="fa fa-arrow-right"></i>
                            </Link>
                            }
                            {layoutType === 'THREE' && <Link to={"/presentation/topics/" + eventCode} className={status ? "btn btn-sm fw-bold btn-primary" : "btn btn-sm fw-bold btn-primary disabled"} >
                                Next <i className="fa fa-arrow-right"></i>
                            </Link>
                            }
                            {layoutType === 'FOUR' && <Link to={"/presentation/preview/" + eventCode} className={status ? "btn btn-sm fw-bold btn-primary" : "btn btn-sm fw-bold btn-primary disabled"} >
                                Next <i className="fa fa-arrow-right"></i>
                            </Link>
                            }
                        </div>

                    </div>
                </div>
                <div id="kt_app_content" className="app-content  flex-column-fluid ">


                    <div id="kt_app_content_container" className="app-container  container-xxl ">

                        <div className="card mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start col-md-6">
                                    <span className="card-label fw-bold fs-3 mb-1">Video with Slides</span>
                                </h3>
                                {slidesData.length > 0 ?
                                    <>
                                        <div className='col-md-4'></div>
                                        <button type="button" className="btn btn-small btn-success" onClick={showSlides} style={{ height: "40px", paddingTop: "10px" }}> <i className="fa fa-eye"></i>      </button>
                                        <button type="button" className="btn btn-small btn-danger" onClick={replaceFile} style={{ height: "40px", paddingTop: "10px" }}> <i className="fa fa-file"></i>      </button>
                                    </>
                                    : ""}
                            </div>

                            <div className="card-body py-3">
                                {alertMsg ?
                                    <SweetAlert
                                        title=""
                                        showConfirm={false}
                                        style={{ width: "200px", height: "100px" }}
                                    > <img src={loading} width="50px" height="50px" />
                                    </SweetAlert>
                                    : <></>}
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 video-js-responsive-container videoContainer">
                                        <div id="videoDiv" className="mt-4">
                                            {videoUrl ? <VideoPlayer videoUrl={videoUrl} videoType={videoType} min={getMinutes} sec={getSeconds} /> : ""}
                                        </div>
                                    </div>
                                        {slidesData.length > 0 ? <>
                                            <div className="col-md-6 col-sm-12">
                                            <form onSubmit={handleUpdateSubmit}>
                                                <div className={flag ? "table-responsive" : "table-responsive divHide"} id="slide-details" >
                                                    <table className="table table-row-bordered align-middle gs-0 gy-3 border mt-3">
                                                        <thead>
                                                            <tr className="fw-bold text-muted">
                                                                <th style={{ paddingLeft: "10px" }}>
                                                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                        S.No
                                                                    </div>
                                                                </th>
                                                                <th className="min-w-150px">Title</th>
                                                                <th>Sequence</th>
                                                                <th className="min-w-120px">Start Time</th>
                                                                <th className="min-w-120px">End Time</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {slidesRequest?.map((item, i) => {
                                                                return (
                                                                    <tr key={item.eventSlideId}>
                                                                        <td style={{ paddingLeft: "10px" }}>
                                                                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                                {i + 1}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <input type="text" className="fw-bold text-hover-primary fs-6" value={item.title}
                                                                                onChange={(e) => onChangeName(e.target.value, item.eventSlideId)} />
                                                                        </td>

                                                                        <td>
                                                                            <input type="number" className="fw-bold text-hover-primary fs-6" value={item.sequence} style={{ width: "40px" }}
                                                                                onChange={(e) => onChangeSequence(e.target.value, item.eventSlideId)} />
                                                                        </td>

                                                                        <td>
                                                                            <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.startTime}
                                                                                onChange={(e) => onChangeStartTime(e.target.value, item.eventSlideId)}
                                                                                placeholder="hh:mm:ss" style={{ width: "65px" }} required />
                                                                            <Link class="btn btn-success btn-table" onClick={() => updateStartTime(item.eventSlideId)}><i className="fa fa-check"></i></Link>
                                                                        </td>

                                                                        <td style={{ paddingRight: "5px" }}>
                                                                            <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.endTime}
                                                                                onChange={(e) => onChangeEndTime(e.target.value, item.eventSlideId)}
                                                                                placeholder="hh:mm:ss" style={{ width: "65px" }} required />
                                                                            <Link class="btn btn-danger btn-table" onClick={() => updateEndTime(item.eventSlideId)}><i className="fa fa-check"></i></Link>
                                                                        </td>

                                                                    </tr>

                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <button type="submit" className="btn btn-sm fw-bold btn-primary text-white" style={{ float: "right", marginBottom: "20px" }}>
                                                        Submit</button>
                                                </div>
                                            </form>
                                            </div>
                                        </>
                                            :
                                            <div className="col-md-6 col-sm-12 text-center">
                                            <div id="uploadIcon" style={{ marginTop: "150px" }}>
                                                <label htmlFor="fileInput" className="file-label">
                                                    <i className="fas fa-cloud-upload-alt"></i>
                                                    Choose a file
                                                </label>
                                                <input type="file" accept=".pdf, .pptx" onChange={handleFileChange} /><br></br>
                                                <button onClick={handleUpload} disabled={uploading} className="btn btn-primary">
                                                    {uploading ? 'Uploading...' : 'Upload'}
                                                </button>
                                                {message && <p>{message}</p>}
                                            </div>
                                            </div>}
                                    </div>

                                <div className='container-fluid mt-3'>
                                    {showToast && <AlertSuccess show={showToast} message={" Slide Details Updated Successfully"} hideToast={hideToast} />}
                                </div>
                                {/*   {slidesData.length > 0 ? 
                                 <div className='row container-fluid mt-3'>
                                <form onSubmit={handleUpdateSubmit}>
                                    <div className={flag ? "table-responsive" : "table-responsive divHide"} id="slide-details" >
                                        <button type="submit" className="btn btn-sm fw-bold btn-primary text-white" style={{ float: "right", marginBottom: "20px" }}>
                                            Submit</button>
                                        <table className="table table-row-bordered align-middle gs-0 gy-3 border mt-8">
                                            <thead>
                                                <tr className="fw-bold text-muted">
                                                    <th className="w-25px" style={{ paddingLeft: "10px" }}>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                            S.No
                                                        </div>
                                                    </th>
                                                    <th className="min-w-150px">Title</th>
                                                    <th className="min-w-150px">Sequence</th>
                                                    <th className="min-w-140px">Start Time</th>
                                                    <th className="min-w-120px">End Time</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {slidesRequest?.map((item, i) => {
                                                    return (
                                                        <tr key={item.eventSlideId}>
                                                            <td style={{ paddingLeft: "10px" }}>
                                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                    {i+1}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary fs-6" value={item.title}
                                                                    onChange={(e) => onChangeName(e.target.value, item.eventSlideId)} />
                                                            </td>

                                                            <td>
                                                                <input type="number" className="fw-bold text-hover-primary fs-6" value={item.sequence} style={{ width: "40px" }}
                                                                    onChange={(e) => onChangeSequence(e.target.value, item.eventSlideId)} />
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.startTime}
                                                                    onChange={(e) => onChangeStartTime(e.target.value, item.eventSlideId)}
                                                                    placeholder="hh:mm:ss" style={{ width: "80px" }} required/>
                                                                     <button class="btn btn-success btn-table" onClick={() => updateStartTime(item.eventSlideId)}><i className="fa fa-check"></i></button>
                                                            </td>

                                                            <td>
                                                                <input type="text" className="fw-bold text-hover-primary mb-1 fs-6" value={item.endTime}
                                                                    onChange={(e) => onChangeEndTime(e.target.value, item.eventSlideId)}
                                                                    placeholder="hh:mm:ss" style={{ width: "80px" }}  required/>
                                                                    <button class="btn btn-danger btn-table" onClick={() => updateEndTime(item.eventSlideId)}><i className="fa fa-check"></i></button>
                                                            </td>

                                                        </tr>

                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                                </div>
                                : ""} */}
                            </div>
                        </div>
                    </div>
                </div>
                {showPreview && <PreviewModal show={showPreview} data={slidesData} onHide={hidePreview} />}
                {showWarning && <WarningModal show={showWarning} cancel={fileUpload} onHide={handleWarningModal} message={"Are You Sure?Replace File will remove previous slides"} />}
                {showFile && <FileUploadModal show={showFile} file={getNewFile} cancel={uploadNewFile} onHide={handleFileModal} />}
                {showNext && <SuccessModal show={showNext} nextscreen={nextScreen} onHide={handleNextModal} message={"Slides Details Submitted Successfully.Click Next to Proceed"} />}
            </div>

        </>
    );
}

export default Slides;