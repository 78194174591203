import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SocialLogin from './SocialLogin';
import { Alert } from 'react-bootstrap';
import './Login.css';

function LoginError() {
    useEffect(() => {
    sessionStorage.clear();
    },[]);

    return (
        <>
            <div id="kt_app_toolbar" class="container-xxl">
            <div className="row mt-8">
            <div className="col-md-4"></div>
            <div className="col-md-4 text-center">
            <div className="mb-30">
              <p className="logo-text text-black">Slidesmap</p>
            </div>
            <div className="mb-30">
            <h1>Let's try that again.</h1>
             <p>Your Google login wasn't successful for some reason.Please try again.</p>
             </div>
             <Alert className="login-alerts" variant="danger">
             <h3>That email address doesn't exist.</h3>
             <p>The email address you have entered doesn't appear to exist.<br></br>
             Please check your entry and try again.</p>
             </Alert>
             <div className="mb-30">
             <SocialLogin/>
             <div className="mt-20">
             <span>Or go to  </span>
             <Link to="/login" className="link-primary fw-semibold">
              Login
             </Link>
             </div>
             </div>
             </div>
             <div className="col-md-4"></div>
             </div>
            </div>
        </>
    );
}

export default LoginError;
