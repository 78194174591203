import React from 'react';
import { LOGIN_GOOGLE_AUTH_URL } from '../../Constants';
import googleIcon from "../../assets/images/google-icon.svg";

function SocialLogin() {
    return (
        <div class="row g-3 mb-9">
            <div class="col-md-12">
                <a href={LOGIN_GOOGLE_AUTH_URL} class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                    <img alt="Logo" src={googleIcon} class="h-15px me-3" />
                    Sign in with Google
                </a>
            </div>
        </div>

    );
}

export default SocialLogin;
